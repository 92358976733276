import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Col, Drawer, Row, Button, Radio, Spin, Form, notification, Input, Switch, InputNumber, Divider } from "antd";
import axios from "axios";
import moment from "moment";
import dayjs from "dayjs";

import mbImage from "../../../assets/icons/MB.svg";
import mbWayImage from "../../../assets/icons/MB-Way.svg";
import bankImage from "../../../assets/icons/Bank.svg";
import endpoints from "../../../utils/endoinpts";
import "./PaymentSteps.css";

function PaymentSteps({ user, open, close }) {
  const [prices, setPrices] = useState([]);
  const [filteredPrices, setFilteredPrices] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [paymentMB, setPaymentMB] = useState({});
  const [paymentMBWay, setPaymentMBWay] = useState({});
  const [userInDebtMonth, setUserInDebtMonth] = useState(false);
  const [userInDebtAnual, setUserInDebtAnual] = useState(false);
  const [paymentSubmitted, setPaymentSubmitted] = useState(false);

  const [disabledMB, setDisabledMB] = useState(false);
  const [disabledMBWAY, setDisabledMBWAY] = useState(false);
  const [disabledMonth, setDisabledMonth] = useState(false);
  const [disabledDebit, setDisabledDebit] = useState(false);
  const [disabledVoucher, setDisabledVoucher] = useState(false);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  useEffect(() => {
    if (open) {
      handleGetPrices();
    }
  }, [open]);

  function handleGetPrices() {
    axios
      .get(endpoints.purchase.getPrices)
      .then((res) => {
        let auxPrices = res.data.filter((value, index) => value.id_profession === user.id_profession && value.retiree === user.retiree);
        console.log(auxPrices);

        if (user.valid_access_date && user.id_profession) {
          let valid_access_date = moment(user.valid_access_date);
          let today = moment(new Date());
          let durationAccesss = moment.duration(today.diff(valid_access_date));
          let yearsAccess = durationAccesss.asYears();
          let monthsAccess = durationAccesss.asMonths();

          for (let i = 0; i < auxPrices.length; i++) {
            if (auxPrices[i].id_payment_plan === 1) {
              if (yearsAccess >= 1) {
                console.log(auxPrices[i]);
                if (auxPrices[i].type !== "debit") {
                  auxPrices[i].base_price = Number(auxPrices[i].price);
                  auxPrices[i].price = Number(auxPrices[i].price) * 2;
                  auxPrices[i].price = auxPrices[i].price.toFixed(2);
                }

                setUserInDebtAnual(true);
                setDisabledDebit(true);
              }
            } else {
              if (monthsAccess >= 1) {
                if (auxPrices[i].type !== "debit") {
                  auxPrices[i].base_price = Number(auxPrices[i].price);
                  auxPrices[i].price = Number(auxPrices[i].price) * parseInt(monthsAccess + 1);
                  auxPrices[i].price = auxPrices[i].price.toFixed(2);
                }
                setUserInDebtMonth(true);
                setDisabledMonth(true);
              }
            }
          }
        }

        setPrices(auxPrices);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleGenerateMB(values) {
    return new Promise((resolve, reject) => {
      let value_price = values.price;
      let userId = user.id;

      do {
        userId = `0${userId}`;
      } while (userId.length < 4);

      let order_id = `${dayjs().format("YYYYMMDD")}${userId}`;

      let price_selected = prices.filter((value, index) => value.price === value_price && value.retiree === user.retiree)[0];

      let data = {
        type: "quotes",
        planId: price_selected.id_payment_plan,
        userId: user.id,
        orderId: order_id,
        amount: value_price.toString(),
        description: price_selected.invoice_item_description,
        userName: user.name,
        userEmail: user.email,
        expiryDays: "3",
      };

      axios
        .post(endpoints.purchase.purchaseMB, {
          data: data,
        })
        .then((res) => {
          setPaymentMB(res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  function handleGenerateMBWay(values) {
    return new Promise((resolve, reject) => {
      let userId = user.id;
      do {
        userId = `0${userId}`;
      } while (userId.length < 4);

      let order_id = `${dayjs().format("YYYYMMDD")}${userId}`;

      let price_selected = prices.filter((value, index) => Number(value.id_payment_plan) === Number(values.payment_info_subscription) && value.retiree === user.retiree)[0];

      let data = {
        type: "quotes",
        planId: price_selected.id_payment_plan,
        userId: user.id,
        orderId: order_id,
        amount: values.price,
        description: price_selected.invoice_item_description,
        phone: values.payment_phone,
        userEmail: user.email,
      };

      axios
        .post(endpoints.purchase.purchaseMBWay, {
          data: data,
        })
        .then((result) => {
          setPaymentMBWay(result.data);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  function handleUpdateUserPaymentMethod(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(endpoints.purchase.updateUserPaymentMethod, {
          data: data,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  function handleSubmitNewPayment(values, res) {
    return new Promise((resolve, reject) => {
      let auxValues = values ? values : res;

      let priceFiltered = prices.filter(
        (value, index) => Number(value.id_payment_plan) === Number(auxValues.payment_info_subscription) && value.type === auxValues.payment_method
      )[0];

      let userId = user.id;

      do {
        userId = `0${userId}`;
      } while (userId.length < 4);

      let order_id = `${dayjs().format("YYYYMMDD")}${userId}`;
      let invoice_item_quantity = 1;

      if (user.valid_access_date) {
        let valid_access_date = moment(user.valid_access_date);
        let today = moment(new Date());
        let durationAccesss = moment.duration(today.diff(valid_access_date));
        let yearsAccess = durationAccesss.asYears();

        if (priceFiltered.id_payment_plan === 1) {
          if (yearsAccess >= 1) {
            invoice_item_quantity = 2;
          }
        }
      }

      let invoice_items = [
        {
          code: priceFiltered.invoice_item_code,
          description: priceFiltered.invoice_item_description,
          price: priceFiltered.base_price ? priceFiltered.base_price : priceFiltered.price,
          quantity: invoice_item_quantity,
          discount: auxValues.voucher && auxValues.voucher.id ? (priceFiltered.id_payment_plan === 1 ? auxValues.voucher.percentage_year : auxValues.voucher.percentage_month) : 0,
          unit: "unit",
        },
      ];

      let auxData = {
        id_user: user.id,
        id_payment_plan: priceFiltered.id_payment_plan,
        date: moment().format("YYYY-MM-DD"),
        type: "quotes",
        method: auxValues.payment_method,
        entity: auxValues.payment_method === "mb" ? res.Entity : null,
        reference: auxValues.payment_method === "mb" ? res.Reference : null,
        iban: auxValues.payment_method === "debit" ? auxValues.iban : null,
        bank_name: auxValues.payment_method === "debit" ? auxValues.bank_name : null,
        bank_user_name: auxValues.payment_method === "debit" ? auxValues.bank_user_name : null,
        phone_number: auxValues.payment_method === "mbway" ? values.payment_phone : null,
        value: auxValues.price,
        process_id: order_id,
        payment_id: auxValues.payment_method === "mbway" ? res.RequestId : auxValues.payment_method === "mb" ? res.RequestId : auxValues.payment_id,
        payment_expiry_date: auxValues.payment_method === "mb" ? res.ExpiryDate : null,
        status: "em validação",
        invoice_items: JSON.stringify(invoice_items),
      };

      axios
        .post(endpoints.purchase.postPurchase, {
          data: {
            userData: {
              ...user,
              profession_name: priceFiltered.profession_name,
            },
            paymentData: auxData,
            type: "user",
            voucherData: auxValues.voucher && auxValues.voucher.id ? auxValues.voucher : null,
          },
        })
        .then((res) => {
          if (auxValues.payment_method === "debit") {
            handleUpdateUserPaymentMethod(auxData)
              .then((result) => {
                resolve(res);
              })
              .catch((error) => {
                console.log(error);
                reject(error);
              });
          } else {
            resolve(res);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  function handleSubmitMB(values) {
    handleGenerateMB(values)
      .then((res) => {
        handleSubmitNewPayment(values, res).then((result) => {
          setTimeout(() => {
            setIsButtonLoading(false);
            setPaymentSubmitted(true);
            handleCompletePayment();
          }, 2000);
        });
      })
      .catch((err) => {
        setIsButtonLoading(false);
        console.log(err);
        notification.error({
          message: `Erro`,
          description: "Aconteceu alguma coisa de errado, por favor tente novamente!",
        });
      });
  }

  function handleSubmitMBWAY(values) {
    handleGenerateMBWay(values)
      .then((res) => {
        if (res.Status === "000") {
          handleSubmitNewPayment(values, res)
            .then((result) => {
              setTimeout(() => {
                setIsButtonLoading(false);
                setPaymentSubmitted(true);
                handleCompletePayment();
              }, 2000);
            })
            .catch((err) => {
              setIsButtonLoading(false);
              console.log(err);
            });
        } else {
          notification.error({
            message: `Erro`,
            description: "O pagamento não foi processado, por favor verifique se está a indicar o seu número corretamente.",
          });
        }
      })
      .catch((err) => {
        setIsButtonLoading(false);
        console.log(err);
        notification.error({
          message: `Erro`,
          description: "Aconteceu alguma coisa de errado, por favor tente novamente!",
        });
      });
  }

  function handleSubmitDebit(values) {
    handleGenerateDebit(values)
      .then((valuesDebit) => {
        handleSubmitNewPayment(null, {
          ...values,
          payment_id: valuesDebit.data.id,
        })
          .then((result) => {
            setTimeout(() => {
              setIsButtonLoading(false);
              setPaymentSubmitted(true);
              handleCompletePayment();
            }, 2000);
          })
          .catch((err) => {
            setIsButtonLoading(false);
            console.log(err);
            notification.error({
              message: `Erro`,
              description: "Aconteceu alguma coisa de errado, por favor tente novamente!",
            });
          });
      })
      .catch((err) => {
        console.log(err);
        setIsButtonLoading(false);
        notification.error({
          message: `Erro`,
          description:
            "Verifique se os seus dados estão corretos. Obrigatoriamente o seu IBAN tem de estar correto! Se tem a certeza que os seus dados estão corretos, por favor contacte-nos",
        });
      });
  }

  function handleGenerateDebit(values) {
    return new Promise((resolve, reject) => {
      let price_selected = prices.filter((value, index) => Number(value.id_payment_plan) === Number(values.payment_info_subscription) && value.retiree === user.retiree)[0];

      axios
        .post(endpoints.purchase.purchaseDebit, {
          data: {
            ...values,
            id_payment_plan: values.payment_info_subscription,
            email: user.email,
            description: price_selected.invoice_item_description,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);
    if (values.payment_method === "mb") {
      handleSubmitMB(values);
    } else if (values.payment_method === "mbway") {
      handleSubmitMBWAY(values);
    } else {
      handleSubmitDebit(values);
    }
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: "Faltam alguns campos para preencher!",
    });
  }

  function handleCompletePayment() {
    handleClose();
    notification.success({
      message: `Sucesso`,
      description: "Estamos à espera que conclua o pagamento. Quando concluído iremos dar acesso à plataforma",
    });
  }

  function handleClose() {
    if (paymentSubmitted === true) {
      close();
      form.resetFields();
      setPrices([]);
    } else {
      let values = form.getFieldsValue();

      if (values.voucher?.id) {
        axios
          .post(endpoints.voucher.updateStockVoucher, {
            data: { voucher_data: values.voucher },
          })
          .then((res) => {
            close();
            form.resetFields();
            setDisabledDebit(false);
            setDisabledMB(false);
            setDisabledMBWAY(false);
            setDisabledMonth(false);
            setDisabledVoucher(false);
          })
          .catch((err) => {
            console.log(err);
            notification.error({
              message: `Erro`,
              description: "Aconteceu alguma coisa de errado, por favor tente novamente!",
            });
          });
      } else {
        close();
        form.resetFields();
        setDisabledDebit(false);
        setDisabledMB(false);
        setDisabledMBWAY(false);
        setDisabledMonth(false);
        setDisabledVoucher(false);
      }
    }
  }

  function handleVerifyVoucher() {
    let voucher = form.getFieldValue("voucher");
    let selected_price = form.getFieldValue("price");
    let payment_info_subscription = form.getFieldValue("payment_info_subscription");
    let payment_method = form.getFieldValue("payment_method");

    axios
      .post(endpoints.voucher.verifyVoucherQuotes, {
        data: {
          type: "quotes",
          name: voucher.name,
          user_id: user.id,
          user_id_profession: user.id_profession,
        },
      })
      .then((res) => {
        let voucherVerificationData = res.data;

        if (voucherVerificationData.voucher_valid) {
          let voucher_data = voucherVerificationData.data.voucherData;

          let auxPrices = JSON.parse(JSON.stringify(prices));

          for (let i = 0; i < auxPrices.length; i++) {
            let newTotal = 0;
            if (voucher_data.voucher_type === "Percentagem") {
              let percentageToValue = (Number(auxPrices[i].price) / 100) * Number(voucher_data.value);
              newTotal = Number(auxPrices[i].price) - parseFloat(percentageToValue).toFixed(2);
              auxPrices[i].old_price = auxPrices[i].price;
              auxPrices[i].base_price = auxPrices[i].price;
              auxPrices[i].price = parseFloat(newTotal).toFixed(2);
              voucher.id = voucher_data.id;
              voucher.percentage = voucher_data.value;
              voucher.percentage_year = voucher_data.value;
              voucher.percentage_month = voucher_data.value;
              setDisabledMonth(true);
              setDisabledDebit(true);
              setDisabledVoucher(true);
              form.setFieldValue("voucher", voucher);
              form.setFieldValue("payment_method", "");
              if (selected_price === auxPrices[i].old_price) {
                form.setFieldValue("old_price", auxPrices[i].old_price);
                form.setFieldValue("price", auxPrices[i].price);
              }
            } else {
              newTotal = Number(auxPrices[i].price) - Number(voucher_data.value);
              let percentage = (Number(voucher_data.value) * 100) / Number(auxPrices[i].price);
              auxPrices[i].old_price = auxPrices[i].price;
              auxPrices[i].base_price = auxPrices[i].price;
              auxPrices[i].price = newTotal >= 0 ? parseFloat(newTotal).toFixed(2) : 0;
              voucher.id = voucher_data.id;
              if (i === 0) {
                voucher.percentage_year = newTotal > 0 ? Number(parseFloat(percentage).toFixed(2)) : 100;
              } else {
                voucher.percentage_month = newTotal > 0 ? Number(parseFloat(percentage).toFixed(2)) : 100;
              }
              setDisabledMonth(true);
              setDisabledDebit(true);
              setDisabledVoucher(true);
              form.setFieldValue("voucher", voucher);
              form.setFieldValue("payment_method", "");
              if (selected_price === auxPrices[i].old_price) {
                form.setFieldValue("old_price", auxPrices[i].old_price);
                form.setFieldValue("price", auxPrices[i].price);
              }
            }
          }

          setPrices(auxPrices);

          notification.success({
            message: "Verificação do Voucher",
            description: "Este voucher foi aceite com sucesso",
          });
        } else {
          notification.error({
            message: "Verificação do Voucher",
            description: voucherVerificationData.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleRemoveVoucher() {
    let values = form.getFieldsValue();
    axios
      .post(endpoints.voucher.updateStockVoucher, {
        data: { voucher_data: values.voucher },
      })
      .then((res) => {
        let auxPrices = JSON.parse(JSON.stringify(prices));
        for (let i = 0; i < auxPrices.length; i++) {
          auxPrices[i].price = auxPrices[i].old_price;
          delete auxPrices[i].old_price;
        }

        form.setFieldValue("old_price", null);
        form.setFieldValue("price", null);
        form.setFieldValue("voucher", []);
        form.setFieldValue("payment_method", null);

        setPrices(auxPrices);
        setDisabledDebit(false);
        setDisabledMB(false);
        setDisabledMBWAY(false);
        setDisabledMonth(false);
        setDisabledVoucher(false);
      })
      .catch((err) => {
        console.log(err);
        notification.error({
          message: `Erro`,
          description: "Aconteceu alguma coisa de errado, por favor tente novamente!",
        });
      });
  }

  function handleChangePrice(e) {
    let value = e.target ? e.target.value : e;
    let priceSelected = prices.filter((item) => item.id === value)[0];
    form.setFieldValue("payment_subscription", e);
    form.setFieldValue("price", priceSelected.price);
    form.setFieldValue("payment_info_subscription", priceSelected.id_payment_plan);
  }

  function handleChangeValues(_, allValues) {
    if (_.payment_method) {
      handleChangePaymentMethod(_.payment_method);
    } else if (_.payment_subscription) {
      handleChangePrice(_.payment_subscription);
    }
  }

  function handleChangePaymentMethod(e) {
    let auxPrices = prices.filter((value, index) => value.type === e);

    setFilteredPrices(auxPrices);
    form.setFieldValue("payment_subscription", null);
  }

  return (
    <Drawer
      className="payment_options"
      title={`Pagamento`}
      width={window.innerWidth > 900 ? 900 : "100%"}
      onClose={handleClose}
      open={open}
      bodyStyle={{ paddingBottom: 80 }}
      maskClosable={false}
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          id="payment-form-step-one"
          className="form-payment"
          layout="vertical"
          onFinish={handleSubmit}
          onFinishFailed={handleWarningMissingFields}
          onValuesChange={handleChangeValues}
        >
          <Row className="w-100 d-flex jc-center ai-center">
            <Col span={24} className="d-flex flex-column jc-center ai-center">
              <Row className="d-flex jc-center ai-center w-100">
                <Col xs={24} sm={24} md={24} lg={18} xl={18} className="d-flex flex-column jc-center ai-center">
                  <p className="payment_title text-center">Escolha o seu plano</p>
                  <p className="payment_text text-center">
                    Escolheu como profissional de saúde: {user?.profession_name} -
                    <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => navigate(`/profile`)}>
                      Alterar
                    </span>
                  </p>
                </Col>
              </Row>
              <Form.Item hidden name="payment_info_subscription">
                <Input type="text" />
              </Form.Item>
              <Form.Item hidden name="price">
                <Input />
              </Form.Item>

              <Divider />
              <Row className="d-flex jc-center ai-center">
                <p className="payment_text bold text-center">Selecione a opção de pagamento</p>
                <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.payment_method !== currentValues.payment_method}>
                  {({ getFieldValue }) =>
                    !getFieldValue("payment_method") ? (
                      <Col span={24}>
                        <Row gutter={[24]} className="mt-20">
                          <Col span={8} className="d-flex jc-center ai-center flex-column">
                            {prices
                              .filter((item) => item.type === "debit")
                              .map((item, index) => (
                                <div
                                  className={`preview_payment_card debit ${index === 0 ? "mt-0" : "mt-20"} ${
                                    userInDebtMonth && item.payment_plan_type === "Mensal" ? "disabled" : userInDebtAnual && item.payment_plan_type === "Anual" ? "disabled" : ""
                                  } w-100`}
                                >
                                  <div className="d-flex flex-column jc-center ai-center w-50 p-10">
                                    <p className="m-0">{item.payment_plan_type}</p>
                                  </div>
                                  <div className="d-flex flex-column jc-center ai-center w-50 p-10">
                                    <Divider className="mb-10 mt-10" style={{ height: "2px", background: "#73a91b", maxWidth: 80, minWidth: 80 }} />
                                    {item.old_price && <p className="payment_old_value">{item.old_price}€</p>}
                                    <p className="payment_value mt-10 mb-10 text-center">
                                      {item.price}€{" "}
                                      {/*item.payment_plan_type === "Mensal" ? {<sup>{userInDebtMonth ? "*" : null}</sup> : <sup>{userInDebtAnual ? "*" : null}</sup>*/}
                                    </p>
                                    <Divider className="mb-10 mt-10" style={{ height: "2px", background: "#73a91b", maxWidth: 80, minWidth: 80 }} />
                                  </div>
                                </div>
                              ))}
                          </Col>
                          <Col span={8}>
                            {prices
                              .filter((item) => item.type === "mbway")
                              .map((item) => (
                                <div className={`preview_payment_card`}>
                                  <p className="m-0">{item.payment_plan_type}</p>
                                  <Divider className="mb-15 mt-15" style={{ height: "2px", background: "#73a91b", maxWidth: 80, minWidth: 80 }} />
                                  {item.old_price && <p className="payment_old_value">{item.old_price}€</p>}
                                  <p className="payment_value mt-10 mb-10 text-center">
                                    {item.price}€ {/*item.payment_plan_type === "Mensal" ? {<sup>{userInDebtMonth ? "*" : null}</sup> : <sup>{userInDebtAnual ? "*" : null}</sup>*/}
                                  </p>
                                  <Divider className="mb-15 mt-15" style={{ height: "2px", background: "#73a91b", maxWidth: 80, minWidth: 80 }} />
                                </div>
                              ))}
                          </Col>
                          <Col span={8}>
                            {prices
                              .filter((item) => item.type === "mb")
                              .map((item) => (
                                <div className={`preview_payment_card`}>
                                  <p className="m-0">{item.payment_plan_type}</p>
                                  <Divider className="mb-15 mt-15" style={{ height: "2px", background: "#73a91b", maxWidth: 80, minWidth: 80 }} />
                                  {item.old_price && <p className="payment_old_value">{item.old_price}€</p>}
                                  <p className="payment_value mt-10 mb-10 text-center">
                                    {item.price}€ {/*item.payment_plan_type === "Mensal" ? {<sup>{userInDebtMonth ? "*" : null}</sup> : <sup>{userInDebtAnual ? "*" : null}</sup>*/}
                                  </p>
                                  <Divider className="mb-15 mt-15" style={{ height: "2px", background: "#73a91b", maxWidth: 80, minWidth: 80 }} />
                                </div>
                              ))}
                          </Col>
                        </Row>
                      </Col>
                    ) : null
                  }
                </Form.Item>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="payment_method jc-center ai-center">
                  <Form.Item
                    noStyle
                    name="payment_method"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, escolha o método de pagamento",
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Row gutter={24}>
                        <Col xs={24} sm={12} md={12} lg={8} xl={8} className="mt-20">
                          <Radio.Button value={"debit"} disabled={disabledDebit} className="mt-20 method_img">
                            <img src={bankImage} />
                            Débito Direto
                          </Radio.Button>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={8} className="mt-20">
                          <Radio.Button value={"mbway"} disabled={disabledMBWAY} className="mt-20 method_img">
                            <img src={mbWayImage} />
                          </Radio.Button>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={8} className="mt-20">
                          <Radio.Button value={"mb"} disabled={disabledMB} className="mt-20 method_img">
                            <img src={mbImage} />
                          </Radio.Button>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.payment_method !== currentValues.payment_method}>
                {({ getFieldValue }) =>
                  getFieldValue("payment_method") ? (
                    <>
                      <Form.Item
                        className="w-100 d-flex jc-center ai-center"
                        name="payment_subscription"
                        rules={[
                          {
                            required: true,
                            message: "Por favor, escolha o plano",
                          },
                        ]}
                      >
                        <Radio.Group className="payment_card_group w-100">
                          <Row className="w-100 d-flex jc-center ai-center">
                            {filteredPrices.map((value, index) => {
                              return (
                                <Col key={index} xs={24} sm={24} md={24} lg={12} xl={12} className="d-flex jc-center ai-center p-20">
                                  <Radio.Button
                                    value={value.id}
                                    className={`payment_card ${value.payment_plan_type === "Mensal" ? (disabledMonth ? "disabled" : "") : ""}`}
                                    disabled={value.payment_plan_type === "Mensal" ? (disabledMonth ? true : false) : false}
                                  >
                                    <p className="user_profession">{value.payment_plan_type}</p>
                                    <p className="payment_type">{value.price_plan_type}</p>
                                    <hr className="payment_separator" />
                                    {value.old_price && <p className="payment_old_value">{value.old_price}€</p>}
                                    <p className="payment_value">
                                      {value.price}€{" "}
                                      {/*item.payment_plan_type === "Mensal" ? {<sup>{userInDebtMonth ? "*" : null}</sup> : <sup>{userInDebtAnual ? "*" : null}</sup>*/}
                                    </p>
                                    <hr className="payment_separator" />
                                    <Button
                                      disabled={value.payment_plan_type === "Mensal" ? (disabledMonth ? true : false) : false}
                                      className="payment_select"
                                      onClick={() => handleChangePrice(value.id)}
                                    >
                                      Selecionar
                                    </Button>
                                  </Radio.Button>
                                </Col>
                              );
                            })}
                          </Row>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item hidden name={"old_price"} className="mb-0">
                        <InputNumber />
                      </Form.Item>
                      {userInDebtAnual || userInDebtMonth ? <p className="f-12 mt-0">* Este valor é a junção dos valores em falta com a futura quota</p> : null}
                      <p className="f-12 mt-0">NOTA: O plano de pagamento ao mês só é possível por débito direto, se estiver algum valor em dívida o mesmo é desabilitado.</p>

                      <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.payment_method !== currentValues.payment_method}>
                        {({ getFieldValue }) =>
                          getFieldValue("payment_method") !== "debit" ? (
                            <Row className="voucher w-100">
                              <div className="voucher_div w-100 d-flex jc-sb">
                                <Form.Item disabled={disabledVoucher} name={["voucher", "name"]} className="mb-0 w-100" label="Aplique aqui o seu voucher">
                                  <Input disabled={disabledVoucher} size="large" placeholder="Voucher" />
                                </Form.Item>
                                <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.voucher !== currentValues.voucher}>
                                  {({ getFieldValue }) => {
                                    return getFieldValue("voucher") && getFieldValue("voucher").id ? (
                                      <div className="button_div d-flex jc-end ai-end h-100 ml-20">
                                        <Button className="submit_voucher" onClick={handleRemoveVoucher}>
                                          Apagar voucher
                                        </Button>
                                      </div>
                                    ) : (
                                      <div className="button_div d-flex jc-end ai-end h-100 ml-20">
                                        <Button className="submit_voucher" onClick={handleVerifyVoucher}>
                                          Aplicar voucher
                                        </Button>
                                      </div>
                                    );
                                  }}
                                </Form.Item>
                                <Form.Item hidden name={["voucher", "id"]} className="mb-0">
                                  <Input />
                                </Form.Item>
                                <Form.Item hidden name={["voucher", "percentage_year"]} className="mb-0">
                                  <Input />
                                </Form.Item>
                                <Form.Item hidden name={["voucher", "percentage_month"]} className="mb-0">
                                  <Input />
                                </Form.Item>
                                <Form.Item hidden name={["voucher", "discount"]} className="mb-0">
                                  <Switch />
                                </Form.Item>
                              </div>
                            </Row>
                          ) : null
                        }
                      </Form.Item>

                      <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.payment_method !== currentValues.payment_method}>
                        {({ getFieldValue }) =>
                          getFieldValue("payment_method") === "mbway" ? (
                            <Row className="step_reference w-100">
                              <Col span={24}>
                                <Form.Item
                                  name="payment_phone"
                                  label="Telemóvel pagamento"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Este campo é obrigatório",
                                    },
                                  ]}
                                >
                                  <Input type="phone" size="large" placeholder="Telemóvel" />
                                </Form.Item>
                              </Col>
                            </Row>
                          ) : getFieldValue("payment_method") === "debit" ? (
                            <Row className="step_reference w-100">
                              <Col span={24}>
                                <Row gutter={[24]}>
                                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                    <Form.Item
                                      name="bank_name"
                                      label="Nome do banco"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Este campo é obrigatório",
                                        },
                                      ]}
                                    >
                                      <Input type="text" size="large" placeholder="Nome do banco" />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                                    <Form.Item
                                      name="iban"
                                      label="IBAN"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Este campo é obrigatório",
                                        },
                                      ]}
                                    >
                                      <Input type="text" size="large" placeholder="IBAN" />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={24}></Row>
                                <Row gutter={[24]}>
                                  <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                                    <Form.Item
                                      name="bank_user_name"
                                      label="Nome do(a) Associado(a)"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Este campo é obrigatório",
                                        },
                                      ]}
                                    >
                                      <Input type="text" size="large" placeholder="Nome do(a) Associado(a)" />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                    <Form.Item
                                      name="bank_user_phone"
                                      label="Telemóvel"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Este campo é obrigatório",
                                        },
                                      ]}
                                    >
                                      <Input type="text" size="large" placeholder="Telemóvel" />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          ) : null
                        }
                      </Form.Item>
                      <Row gutter={24} className="mt-20">
                        {user?.id_profession && currentStep === 0 ? (
                          <>
                            <Col span={12} className={"d-flex jc-end"}>
                              <Button className="payment_button back" key="submit" type="primary" onClick={handleClose}>
                                Voltar
                              </Button>
                            </Col>
                            <Col span={12} className={"d-flex jc-start"}>
                              <Button className="payment_button" key="submit" type="primary" onClick={form.submit} loading={isButtonLoading} disabled={isButtonLoading}>
                                Comprar
                              </Button>
                            </Col>
                          </>
                        ) : null}
                      </Row>
                    </>
                  ) : null
                }
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default PaymentSteps;
